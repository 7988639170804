import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Latest Release`}</h2>
    <p>{`This is the changelog information of latest version and if you have any ideas or feedback for improvements in the next version please tell us from the feedback section`}</p>
    <div style={{
      "backgroundColor": "rgb(244, 242, 252)",
      "color": "rgb(135, 91, 247)",
      "padding": "1rem",
      "borderBottom": "1px solid #875bf7",
      "padding": "1rem"
    }}>
  <p style={{
        "fontWeight": "700"
      }}>12 September 2024</p>
    </div>
    <h3><strong parentName="h3">{`Development Library Updates`}</strong>{` `}<br /></h3>
    <p><strong parentName="p">{`Crossplatform React Native - Release Version : 1.0.0`}</strong>{` `}<br /></p>
    <p><strong parentName="p">{`New`}</strong>{`: React Native for Legion is now Available! `}<br /></p>
    <p><strong parentName="p">{`New`}</strong>{`: New `}<inlineCode parentName="p">{`Anchor`}</inlineCode>{` Component for React Native. `}<br /></p>
    <p><strong parentName="p">{`New`}</strong>{`: New `}<inlineCode parentName="p">{`Avatar`}</inlineCode>{` Component for React Native. `}<br /></p>
    <p><strong parentName="p">{`New`}</strong>{`: New `}<inlineCode parentName="p">{`Bottom Sheet`}</inlineCode>{` Component for React Native. `}<br /></p>
    <p><strong parentName="p">{`New`}</strong>{`: New `}<inlineCode parentName="p">{`Button`}</inlineCode>{` Component for React Native. `}<br /></p>
    <p><strong parentName="p">{`New`}</strong>{`: New `}<inlineCode parentName="p">{`Chips`}</inlineCode>{` Component for React Native. `}<br /></p>
    <p><strong parentName="p">{`New`}</strong>{`: New `}<inlineCode parentName="p">{`Textarea`}</inlineCode>{` Component for React Native. `}<br /></p>
    <p><strong parentName="p">{`New`}</strong>{`: New `}<inlineCode parentName="p">{`Textfield`}</inlineCode>{` Component for React Native. `}<br /></p>
    <p><strong parentName="p">{`New`}</strong>{`: New Installation Guideline for React Native. `}<br /></p>
    <h3><strong parentName="h3">{`Design Library Updates`}</strong>{` `}<br /></h3>
    <ul>
      <li parentName="ul">
        <br />
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      